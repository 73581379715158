.Skills {
  margin: 0 50px;
  padding-top: 50px;
}

.Skills__title {
  font-size: 32px;
  text-align: center;
}

.Skills__content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.Skills__content > * {
  flex-basis: 25%;
  box-sizing: border-box;

  @media (max-width: 599px) {
    flex-basis: 100%;
  }
}

.TileContainer {
  display: flex;
  flex-direction: "row";
  flex-wrap: wrap;
}

.TileContainer > * {
  flex-basis: 33%;
  flex-shrink: 0;
  flex-grow: 0;
  box-sizing: border-box;

  @media (max-width: 599px) {
    flex-basis: 100%;
    
  }
}

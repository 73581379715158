@import "../../shared/styles/breakepoints.scss";
@import "../../shared/styles/colors.scss";

.AboutMe {
  display: flex;
  flex-direction: column;
  background-color: #dfdfdf;
  padding-top: 50px + 36px;
  padding-bottom: 32px;
}

.AboutMe__content {
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: $layout-xs-max) {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.AboutMe__text {
  padding: 0 20px;
  max-width: 500px;
}

.AboutMe__short {
  font-size: 24px;
}

.AboutMe__jobtitle {
  font-size: 40px;
  font-weight: 700;
  color: $primary;

  @media (max-width: $layout-xs-max) {
    font-size: 32px;
  }
}

.AboutMe__avatar {
  width: 300px;
}

.AboutMe__avatar > img {
  width: 100%;
  border-radius: 8px;
}

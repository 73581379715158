@import "../../shared//styles/colors.scss";

.Footer {
  background-color: $white;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 16px;
}

.Footer__copyright {
  font-size: 18px;
  margin-right: 50px;
  color: $gray-dark;
}

.Footer__links {
  & > a {
    padding: 8px;
    text-decoration: none;
    font-size: 16px;
    color: #000;

    &:hover {
      text-decoration: underline;
    }
  }
}

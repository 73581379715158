@import "../../shared/styles/colors.scss";
@import "../../shared/styles/breakepoints.scss";

.ContactForm {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 600px;

  margin-top: 50px;

  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 600px;

  @media (max-width: $layout-xs-max) {
    width: 100vw;
  }

  &__buttonWrapper {
    margin: 32px 4px;
  }
}

.ContactForm__form {
  flex: 1 auto;
}

.ContactForm__address {
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;

  @media (max-width: $layout-xs-max) {
    flex-direction: column;
  }

  > * {
    flex: 50%;

    @media (max-width: $layout-xs-max) {
      flex: auto;

      & + * {
        margin-top: 32px;
      }
    }
  }
}

.NotValidText {
  color: $orange;
  font-size: 36px;
  margin-bottom: 20px;
}

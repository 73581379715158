//https://coolors.co/f15025-ffffff-e6e8e6-ced0ce-191919

$primary: #227bd4;
$white: #ffffff;
$gray-light: #e6e8e6;
$gray: #ced0ce;
$gray-dark: #191919;

// depracted
$orange: $primary;

@import "../styles/colors.scss";

.TextField {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 4px;  
}

.TextField__label {
  text-align: start;
  font-size: 24px;
  margin-bottom: 8px;
}

.TextField__input {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid $white;
  color: $white;
  font-size: 16px;
}

.TextField__input:focus {
  outline: none;
  border-bottom-color: $orange;
  caret-color: $white;
}

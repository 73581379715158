@import "../../shared/styles/colors.scss";
@import "../../shared//styles/breakepoints.scss";

.Toolbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;

  color: #000;
  background-color: #f0f0f0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  //overflow: hidden;
  padding: 0 16px;

  box-shadow: 0 2px 2px #ccc;

  &__brand {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 36px;
    color: $gray;

    margin-right: 16px;

    img {
      width: 36px;
      height: 36px;
      margin-right: 16px;
    }

    &:hover {
      color: $gray-light;
    }
  }
}

.Toolbar__social {
  display: flex;
  flex-direction: row;
}

.Toolbar_socialLink {
  display: block;
  height: 22px;
  margin: 8px;
  color: $white;
  font-size: 24px;

  &:hover {
    color: $white;
    border-bottom: 1px solid $white;
  }

  > img {
    width: 100%;
  }

  @media (max-width: $layout-xs-max) {
    display: none;
  }

  &--hidden {
    display: none;
  }
}

.Toolbar__menu {
  display: flex;
  flex-direction: row;
  // flex-grow: 1;

  @media (max-width: $layout-xs-max) {
    display: none;
  }

  &.Toolbar__menu--open {
    position: absolute;
    right: 0;
    top: 50px;
    display: flex;
    flex-direction: column;
    background-color: #777;
    width: 100vw;
  }
}

.Toolbar__link {
  padding: 8px;
  color: inherit;
  text-decoration: none;
}

.Toolbar__link:hover {
  text-decoration: underline;
}

.Toolbar__link--active {
  color: $primary;
}

.Toolbar__menuToggle {
  display: flex;

  @media (min-width: $layout-sm-min) {
    display: none;
  }
}

.Toolbar__menuToggleButton {
  color: black;
  font-size: 24px;
  cursor: pointer;
  border: 0;
  background: transparent;
}

@import "../styles/colors.scss";

.TextArea {
  display: flex;
  flex-direction: column;
  padding: 4px;
}

.TextArea__label {
  color: $white;
  text-align: start;

  font-size: 24px;
  margin-bottom: 8px;
}

.TextArea__field {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid $white;
  color: $white;
  max-width: 100%;
  min-width: 200px;

  font-size: 16px;
}

.TextArea__field:focus {
  outline: none;
  border-bottom-color: $orange;
  caret-color: $white;
}

.Contact {
  background-color: #191919;
  color: #fff;
  padding: 50px 16px 0px;
  min-height: calc(100vh - 50px - 50px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.Contact__title {
  font-size: 48px;
}

.Contact__formWrapper {
  margin-bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  display: none;
}

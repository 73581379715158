@import "../styles/colors.scss";
@import "../styles/typo.scss";

.Button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.Button__element {
  background: $primary;
  border: none;
  padding: 16px;
  min-width: 160px;
  color: $white;
  font-size: 20px;

  font-family: $primaryFont;
}

.Tile {
  display: flex;
  flex-direction: column;
  padding: 32px;  
}

.Tile__header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
    align-items: flex-start;
  margin-bottom: 16px;
}

.Tile__icon {
    width: 48px;
    height: 48px;
    font-size: 48px;
    margin-bottom: 16px;
}

.Tile__title {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  font-size: 22px;
}

.Tile__content {
  text-align: start;
}

@import "../../shared/styles/breakepoints.scss";

.ContactPoints {
  width: 600px;
  margin-bottom: 32px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: $layout-xs-max) {
    width: initial;
  }

  &__point {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 32px;

    @media (max-width: $layout-xs-max) {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }

  &__icon {
    font-size: 36px;
    width: 50px;
  }

  &__email,
  &__phone,
  &__address {
    margin-left: 16px;
    font-size: 24px;
    text-align: start;

    @media (max-width: $layout-xs-max) {
      margin-left: 0;
    }
  }

  &__github,
  &__linkedin,
  &__xing,
  &__twitter {
    margin-left: 16px;
    font-size: 24px;
    text-align: start;
    text-decoration: none;
    color: white;

    &:hover {
      text-decoration: underline;
    }

    @media (max-width: $layout-xs-max) {
      margin-left: 0;
    }
  }
}
